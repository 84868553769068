<template>
  <v-card>
    <Header :title="$t('profile.heading')" showEdit >
    </Header>
<!--     <v-card-title class="display-2">
      <v-row>
        <v-col class="pa-0 pl-4 grow">{{$t('profile.heading')}}</v-col>
        <v-col class="pa-0 pr-4 shrink">
          <v-btn class="d-none d-sm-flex" color="accent" :to="{name:'profileEdit'}"><v-icon small class="fal fa-edit mr-2" /> {{$t('profile.edit-profile')}}</v-btn>
          <v-btn class="d-flex d-sm-none" small color="accent" :to="{name:'profileEdit'}"><v-icon small class="fal fa-edit mr-2" /> {{$t('shared.edit')}}</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
 -->

    <v-alert v-if="profile && profile.random_name" tile type="success" prominent>
      <v-row align="center">
        <v-col class="grow" cols="12" md="8">
          <i18n path="profile.name-intro">
            <strong>{{profile.name}}</strong>
          </i18n>
        </v-col>
        <v-col class="shrink" cols="12" md="4">
          <v-btn :to="{name:'profileEdit'}">{{$t('profile.change-name')}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert v-else-if="profile && !profile.email" tile type="success" prominent>
      <v-row align="center">
        <v-col class="grow" cols="12" md="8">{{$t('profile.email-intro')}} </v-col>
        <v-col class="shrink" col2="12" md="4">
          <v-btn :to="{name:'profileEdit'}">{{$t('profile.update-email')}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
      <v-alert v-if="tenant.id == 'wmm' && profile && (this.profile.meta == null || !this.profile.meta.some(x => x.key == 'wmm.migrated.on'))" type="info" tile>
        <div class="message-plus-button">
          <p>Complete your profile onboarding</p>
          <v-btn class="shrink white--text" :to="{name: 'profileOnboarding' }">Start</v-btn>
        </div>
      </v-alert>

    <v-container  class="grey lighten-4 px-0">
    <v-card-title class="subtitle">{{$t(tenant.nameEventsAsChallenges ? 'profile.challenges-joined' : 'profile.events-joined')}}</v-card-title>
    <v-card-text v-if="!profile">
      <v-progress-circular indeterminate />
    </v-card-text>
    <v-card-text v-if="profile && (!profile.events || profile.events == 0)">
      <p class="mb-0">{{$t(tenant.nameEventsAsChallenges ? 'profile.challenges-joined-none' : 'profile.events-joined-none')}}</p>
    </v-card-text>      
    <div v-else>
    <v-row v-if="profile && profile.events" class="mx-1">
      <template v-for="(item, idx) in (profile.events)">
        <v-col 
          :key="item.o ? item.o.id : item.id"
          v-if="$helpers.eventIsRelevantForDisplay(item)"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <EventSummaryCard :summary="item" :index="idx" />
        </v-col>
      </template>
    </v-row>
    <v-card-text>
      <v-btn outlined :to="{name: 'profileEvents'}" color="primary" class="">
        {{$t('shared.show-all')}}
      </v-btn>      
    </v-card-text>
  </div>
    </v-container>

    <v-divider />
<!-- 
    <div v-if="results == null">
      <v-card-title class="subtitle">{{$t('profile.results.title')}}</v-card-title>
      <v-card-text><i><v-icon>fa fa-circle-notch fa-spin</v-icon> {{ $t('shared.loading') }}</i></v-card-text>
    </div>
    <div v-else-if="profile && results && results.data.length > 0">
      <MyRaceResultsOverview :results="results.data"/>
      <MyTrainingPlanOverview ref="trainingPlans" :results="results.data"/>
    </div> -->

    <AdList :ads="ads" />

    <div v-if="featured && featured.length">
      <v-card-title class="subtitle">{{$t('shared.featured')}}</v-card-title>
      <EventGrid :items="featured" class="mx-4 mb-4"/>
    </div>

    <v-card-title class="subtitle">{{$t('profile.activities.title')}}</v-card-title>
    <v-card-text>
      {{$t('profile.activities.intro')}}
    </v-card-text>
    <ActivityGrid :limit="5" :profile="profile" compact ref="activityGrid" />

    <v-card-text>      
      <v-btn outlined color="primary" v-if="user" :to="{ name: 'activities' }" :exact="true">{{$t('profile.activities.all')}}</v-btn>
      <v-btn v-if="!tenant.hideManualEntry" text color="primary" :to="{name: 'activityEntry'}" :exact="true">{{$t('profile.activities.manualentry')}}</v-btn>
      <v-btn text color="primary" :to="{name: 'activityUpload'}" :exact="true">{{$t('profile.activities.upload')}}</v-btn>
    </v-card-text>      

    <MyBadgesOverview :badges="profile && profile.badges"/>

    <!--<div id="ga_20278199"> </div><div style="text-align:right; width:300px; padding:5px 0;">
      <img src="https://bcdn.grmtas.com/images/healthyads-logo.png" alt="logo" style="float:right; border:none;" />
      <div style="width:auto; padding:1px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;">
          <a href="https://www.healthyads.com" target="_blank" title="Health Ads" style="text-decoration:none; color:#333;">Health Ads</a> by
      </div>
    </div>-->

    <v-divider />
    <!-- <v-card-title class="subtitle">Welcome {{ profile.name }}</v-card-title> -->
    <!-- <ProfileConnectPanel :profile="profile" :providerUrls="providerUrls" /> -->

    <div v-if="$oauth.hasConnection(profile)">
      <v-card-title class="subtitle">{{$t('profile.connections.status')}}</v-card-title>
      <v-card-text>
        <v-alert type="info">
          <i18n path="profile.connections.configured">
            <strong>{{profile.providers.map(x => $options.filters.titleize(x)).join(' + ')}}</strong>
          </i18n>
        </v-alert>
        <p v-if="!profile.sync">{{$t('profile.connections.status-empty')}}</p>
        <v-alert v-else-if="profile.sync.status == 'OK'" type="success">{{$t('profile.connections.status-success', { timestamp: $options.filters.localDate(profile.sync.timestamp, "L LTS") })}}</v-alert>
        <v-alert v-else-if="profile.sync.status == 'INFO'" type="info">{{$t('profile.connections.status-info', { timestamp: $options.filters.localDate(profile.sync.timestamp, "L LTS"), message: profile.sync.msg })}}</v-alert>
        <v-alert v-else type="error">{{$t('profile.connections.status-error', { timestamp: $options.filters.localDate(profile.sync.timestamp, "L LTS"), message: profile.sync.msg })}}</v-alert>
      </v-card-text>      
      <v-card-text class="pt-0">
        <v-btn outlined :to="{name: 'profileConnections'}" color="primary" class="">
          {{$t('profile.connections.manage')}}
        </v-btn>
      </v-card-text>      

      <v-divider />
    </div>
    <v-alert v-else type="info" prominent tile>
      <h3>{{ $t('profile.connections.connect') }}</h3>
      <p>{{ $t('profile.connections.connect-msg') }}</p>
      <v-btn :to="{name: 'profileConnections'}" color="primary" class="">
        {{$t('shared.configure')}}
      </v-btn>
    </v-alert>

    <QuickSurvey 
      v-if="tenant.id=='cofi' && results && results.data.length > 0" 
      name="cofi-profile-experience"
      question="Do you like your experience with CorporateFitness.app so far?" 
      :items="[{value:'yes', text:'Yes, love it!'}, {value:'neutral', text:'It\'s okay'}, {value:'no', text:'Not so much'}]"
      class="ma-4"
      >
      <template v-slot:voted="{ item }">
        <div v-if="item && item.value == 'yes'" class="mb-0">
          <p>Thanks for your feedback! Would you mind to give us a review? That would be very helpful!</p>
          <p>
            <v-btn large color="primary" href="https://reviews.capterra.com/new/357821/115888ee-151b-4e48-9920-774dd1ec9539?lang=en" target="_blank">Give Review</v-btn>
          </p>
        </div>
      </template>
    </QuickSurvey>


    <!-- <div v-if="profile">
      <v-card-actions>      
        <v-spacer/>
        <v-btn v-if="user" text  @click.prevent="signOut">{{ $t('nav.account.sign-out') }}</v-btn>
      </v-card-actions>       
    </div>       -->

    <v-btn
      v-if="!tenant.hideManualEntry"
      color="accent"
      dark
      fixed
      bottom
      right
      fab
      :to="{name: 'activityEntry'}" :exact="true"
      :title="$t('profile.activities.manualentry')"
    >
      <v-icon>fa-plus</v-icon>
    </v-btn>
  </v-card>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import { getAuth, signOut } from "firebase/auth";
import { DateTime } from "luxon";
import EventGrid from "@/components/EventGrid.vue";
import ActivityGrid from '@/components/ActivityGrid.vue';
import ShareButton from '@/components/ShareButton.vue';
import AdList from '@/components/AdList.vue';
import MyRaceResultsOverview from '@/components/MyRaceResultsOverview.vue';
import MyBadgesOverview from '@/components/MyBadgesOverview.vue';
import EventSummaryCard from '@/components/profile/EventSummaryCard.vue';
import QuickSurvey from '@/components/QuickSurvey.vue';
import MyTrainingPlanOverview from '@/components/MyTrainingPlanOverview.vue';
import ProfileConnectPanel from '@/components/ProfileConnectPanel.vue';
import Header from './_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    ActivityGrid,
    ShareButton,
    QuickSurvey,
    AdList,
    MyRaceResultsOverview,
    MyBadgesOverview,
    MyTrainingPlanOverview,
    EventSummaryCard,
    ProfileConnectPanel,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      now: DateTime.now(),
      DateTime: DateTime,
      profile: null,
      results: null,
      ads: null,
      featured: null,
      providerUrls: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    async signOut() {
      const auth = getAuth();
      await signOut(auth)
      this.$store.dispatch('setUser', null);
      this.$router.replace({ name: "home" });
    },

    async getProfile() {
      if (this.user) {
        try{
          var response = await profileService.get(/*full:*/false);
          //var response = {data:{}};
        }
        catch {
          // network error occur after api has been deployed causing tokens to be invalidated
          // reload the page to prevent empty profile page from showing
          //window.location.reload(true);
          return;
        }
        this.profile = response.data;
        this.name = this.profile.name;
        this.email = this.profile.email;

        
        this.$nextTick(async () => {
          this.ads = (await profileService.getAds()).data.data;
        });

        this.providerUrls = this.$oauth.getProviderUrls({ profileId: this.profile.id });

        //this.results = (await profileService.getResults()).data;
        // this.$nextTick(() => {
        //   if (this.$refs.trainingPlans) {
        //     this.$refs.trainingPlans.load();
        //   }
        // });
        
        if (tenant.id == 'wmm') {
          this.$nextTick(async () => {
            this.featured = (await eventService.getFeatured()).data.data;
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  
</style>

